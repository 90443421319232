const api = {
    //本地：http://localhost:8084
    //测试服务器：http://localhost:8084
    index: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/index/test', //首页只有这一个接口
    siteList:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/site/num',//获得场馆列表调取接口
    // 美术馆接口
    ArtWechat: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/num?venueId=24',                //微信访问数据
    ArtReceive: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/receive?type=2',         //场馆接待数据
    ArtCollect: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/name?type=2',                   //馆藏作品收藏
    ArtPublish: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/zhanlan',             //展览作品发布
    ArtFans: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/fans?type=2',               //微信粉丝
    ArtOverview: 'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/all?type=2',           // AJAX活动报名，场馆预约，直播发布，场馆接待
    ArtSite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishu/siteAll?type=2',          //场馆数据
    // 分馆
    AWechat:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/num?type=2',
    AReceive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/receive?type=2',
    ACollect:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/name?type=2',
    APublish:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/zhanlan?type=2',
    AFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/fans?type=2',
    AOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/all?type=2',
    ASite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/meishuFen/siteAll?type=2',
    // 文化馆接口
    CulturalWebsite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/num4?type=1',                //网站访问
    CulturalReceive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/receive6?type=1',        //接待数据
    CulturalArtist:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/artist?type=1',              //艺术家等级
    CulturalWorks:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/sinatv3?type=1',            //直播访问排行榜
    CulturalSinatv:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/sinatv2?type=1',                     //直播数据请求
    CulturalFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/fansAll?type=1',                 //微信粉丝排行 
    CulturalNotice:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/note?type=1',                 //活动预告
    CulturalOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/countAllZong?type=1',        //总览
    CulturalStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/data1?type=1',        //数据统计
    CulturalSite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/siteAll?type=1',//场馆数据
    CulturalFunctions:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/functions?type=1',//文化馆职能职责
    CulturalActive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/view2?type=1',//馆办活动
    CulturalHeritage:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/heritage2?type=1',//非遗新项目
    // 分馆
    CbranchWebsite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/num5?type=1',         //网站访问数据
    CbranchReceive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/receive5?type=1',       //接待数据
    CbranchSinatv:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/sinatv?type=1',         //直播数据请求
    CbranchStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/data?type=1',         //数据统计
    CbranchOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/countAll?type=1',             //总览
    CbranchNotice:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/noteFen?type=1',             //活动预告
    CbranchFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/fans?type=1',//微信粉丝排行
    CbranchWorks:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/works?type=1',//艺术品占比情况
    CbranchArtist:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/artistFen?type=1',//艺术家等级
    CbranchFunctions:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/two/functions2?type=1',//文化馆职能职责
    // 图书馆
    LibraryBrowed:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/que',//图书借还
    LibraryTop:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/name?type=3',//图书总量排行
    LibraryFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/fansAll?type=3',//粉丝数据
    LibraryStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/data?type=3',//数据统计
    LibraryOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/all?type=3',//数据总览
    LibraryProportion:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/msg?type=3',//藏书占比
    LibrarySite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushu/siteAll?type=3',//场馆数据
    // 分馆
    LbranchLend:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/lend?type=3',//图书借出数据
    LibraryReturn:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/return?type=3',//图书归还排行
    LbranchAge:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/age?type=3',//读者年龄
    LbranchStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/data?type=3',//数据统计
    LbranchOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/all?type=3',//总览
    LbranchTotal:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn//api/tushuFen/cum?',//本年累计人数，本日累计人数，本月累计人数，本日实到人数
    LibranchProportion:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/msg?type=3',//藏书占比
    LbranchFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/fansAll?type=3',//微信粉丝排行
    LbranchRecive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/tushuFen/count?type=3',//场馆接待数据
    // 博物馆
    MuseumWebsite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/num?type=4',//网站访问数据
    MuseumReceive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/receive?type=4',//场馆接待数据
    MuseumCultural:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn//api/bowu/artist?type=4',//文物等级
    MuseumCulturalType:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/works?type=4',//文物类型占比
    MuseumOrder:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/yuyue?type=4',//场馆预约数据
    MuseumOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/countAllZong?type=4',//数据总览
    MuseumStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/data?type=4',//数据统计
    MuseumSite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowu/siteAll?type=4',//场馆数据
    // 分馆
    MbranchWebsite:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/num?type=4',//网站访问数据
    MbranchRecive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/receive?type=4',//接待数据
    MbranchCultural:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/wenwu?type=4',//文物价值占比
    MbranchActive:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/huodong?type=4',//活动报名情况
    MbranchCulturalType:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/works?type=4',//文物类型占比
    MbranchOrder:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/yuyue?type=4',//场馆预约
    MbranchStatistics:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/data?type=4',//数据统计
    MbranchOverview:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/countAllZong?type=4',//总览
    MbranchFans:'https://zhihuiwenlvdapingapp.dev.idunshan.ynccxx.cn/api/bowuFen/Wechat?type=4',//微信粉丝
};
export default api;
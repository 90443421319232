<template>
  <div class="container">

    <div class="header">
      <div class="title">
        <p>曲靖文旅调度系统</p>
      </div>
    </div>
    <div class="all_container" >

      <!-- 线条 -->

     
      <div class="left w360">
        <ul id="site" class="scroll-list">
          <h1> 场馆总数量：{{ site.count || 0 }}</h1>
          <li v-for="(item, index)  in site.list" :key="index"><em v-if="item.name"></em>{{ item.name }} <span v-if="item.name">{{ item.count }}个</span>
          </li>
        </ul>
        <ul class="scroll-list">
          <h1>访问总人数：{{ visit.count || 0 }}</h1>
          <li v-for="(item, index)  in visit.list" :key="index"><em v-if="item.name"></em> {{ item.name }} <span v-if="item.name">{{ item.count }}人</span>
          </li>
        </ul>
      </div>
      <div class="right w360">
        <ul id="activeSite" class="scroll-list">
          <h1>组织活动总数：{{ active.count || 0 }}</h1>
          <li  v-for="(item, index)  in active.list" :key="index"><em v-if="item.name"></em>{{ item.name }} <span v-if="item.name">{{ item.count }}次</span>
          </li>
        </ul>
        <ul id="siteFans" class="scroll-list">
          <h1>微信粉丝总量：{{ fans.count || 0 }}</h1>
          <li v-for="(item, index)  in fans.list" :key="index"><em v-if="item.name"></em>{{ item.name }} <span v-if="item.name">{{ item.count }}人</span>
          </li>
        </ul>
      </div>
      <div class="middle">
        <div class="name">
          <router-link to="/CulturalCenter">
            <img src="@/assets/dist/image/nameone.png" />
            <p>文化馆</p>
          </router-link>
        </div>
        <div class="name">
          <router-link to="/Museum">
            <img src="@/assets/dist/image/nametwo.png" />
            <p>博物馆</p>
          </router-link>
        </div>
        <div class="name">
          <router-link to="/ArtMuseum">
            <img src="@/assets/dist/image/namethree.png" />
            <p>美术馆</p>
          </router-link>
        </div>
        <div class="name">
          <router-link to="/library">
            <img src="@/assets/dist/image/namefor.png" />
            <p>图书馆</p>
          </router-link>
        </div>
        <div class="map_box">
          <img src="@/assets/dist/image/map1.png" alt="" class="map" />
          <div class="areaname areao">
            <p>会泽县</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areat">
            <p>宣威市</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areats">
            <p>沾益区</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areaf">
            <p>马龙区</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areafs">
            <p>麒麟区</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areas">
            <p>富源县</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areatss">
            <p>陆良县</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname areae">
            <p>师宗县</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          <div class="areaname arean">
            <p>罗平县</p>
            <img src="@/assets/dist/image/dian.gif" alt="" class="dian" />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import '../assets/index.css';

// import'@/assets/dist/echarts'
import axios from 'axios'
import api from '@/assets/dist/api'
import { onMounted, onBeforeUnmount, reactive, onActivated, toRefs } from 'vue';
// import { reactive } from 'vue'

export default {
  name: 'HomeView',

  components: {
   

  },
  setup() {
    let timer = null;
    const state = reactive({
      site: {},
      active: {},
      visit: {},
      fans: {},
      total: [],
    })
    function loadData() {
      axios.get(api.index).then(response => {
        const total = response.data.data;
        state.total = total.map(item => reactive(item));
        if (state.total[0]) state.site = state.total[0];
        if (state.total[1]) state.active = state.total[1];
        if (state.total[2]) state.visit = state.total[2];
        if (state.total[3]) state.fans = state.total[3];
        console.log(total);
      }).catch(error => {
        console.log(error)
      });
    }
    function startTime() {
      timer = setInterval(loadData, 5000);
      loadData();
    }



    onMounted(() => {
      loadData()
      startTime()
    });

    onBeforeUnmount(() => {
      // 在 beforeUnmount 中清除定时器
      clearInterval(timer);
    });

    onActivated(() => {
      startTime()
    });

    return {
      ...toRefs(state),
    };
  },
}
</script>
<style></style>

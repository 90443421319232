import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '曲靖文旅调度系统'
    },
  },
  {
    path: '/CulturalCenter',
    name: 'CulturalCenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CulturalCenter.vue'),
    meta: {
      title: '文化馆'
    },
  },
  {
    path: '/ArtMuseum',
    name: 'ArtMuseum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtMuseum.vue'),
    meta: {
      title: '美术馆'
    },
  },
  {
    path: '/library',
    name: 'library',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/library.vue'),
    meta: {
      title: '图书馆'
    },
  },
  {
    path: '/Museum',
    name: 'Museum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Museum.vue'),
    meta: {
      title: '博物馆'
    },
  },
  {
    path: '/CulturalCenter1',
    name: 'CulturalCenter1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CulturalCenter1.vue')
  },
  {
    path: '/ArtMuseum1',
    name: 'ArtMuseum1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArtMuseum1.vue')
  },
  {
    path: '/Museum1',
    name: 'Museum1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Museum1.vue')
  },
  {
    path: '/library1',
    name: 'library1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/library1.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
